import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const FORGOT = "forgot";
export const VERIFY_FORGOT = "verifyForgot"
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_PROFILE = "updateProfile";
export const RESET_PASSWORD = "resetPaswword";

// mutation typesch
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_OBJECT = "setAuthObject";

export const USER_SELECTED = "userSelected"
export const SET_USER_SELECTED = "setUserSelected"





const state = {
  errors: null,
  user: {},
  siswa: false,
  isAuthenticated: !!JwtService.getToken(),
  currentSelectedUser: {

  }
};

const getters = {
  currentUser(state) {
    if (typeof state.user.name === "undefined") {
      state.user = JSON.parse(JwtService.getToken())
    }
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  authUser(state) {

    if (typeof state.user.name !== "undefined") {
      return state.user;
    }

    const user = JSON.parse(JwtService.getToken())
    return user
  },
  currentUserSelected(state) {
    return state.currentSelectedUser;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({
          data
        }) => {
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({
          response
        }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data.errors);
          reject()
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(JwtService.getToken())
      Object.assign(credentials.body, {
        vendor_id: user.vendor_id
      })

      ApiService.post("auth/register", credentials.body)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.message);
          reject()
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("user/profile")
        .then(({
          data
        }) => {
          // if(data.data.user.level==1){
          //   context.commit(SET_OBJECT, {siswa: true})
          // }
          context.commit(SET_AUTH, data.data);
        })
        .catch((error) => {
          const response = error.response;

          if (error.toString().includes('401')) {
            context.commit(PURGE_AUTH, {})
          }

          context.commit(SET_ERROR, response);
        });
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("user/change-password", password).then(({
      data
    }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_PROFILE](context, payload) {
    const password = payload;

    return ApiService.put("user/profile", payload.body).then(({
      data
    }) => {
      context.commit(SET_AUTH, data.data);
      return data;
    });
  },
  [USER_SELECTED](context, payload) {
    console.log(payload);
    context.commit(SET_USER_SELECTED, payload);
  },

  [FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/forgot-password", credentials)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch(({
          response
        }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data.errors);
          reject()
        });
    });
  },
  [RESET_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/change-password", credentials)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject()
        });
    });
  },
  [VERIFY_FORGOT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`auth/verify-forgot-password?` + payload)
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {

          console.log(error);
          // context.commit(SET_ERROR, response.data.message);
          reject();
        });
    });

  },


};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(JSON.stringify(state.user));
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    localStorage.clear();
  },
  [SET_OBJECT](state, payload) {
    Object.assign(state, {
      ...payload,
    })
  },
  [SET_USER_SELECTED](state, data) {
    console.log(data);
    state.currentSelectedUser = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};