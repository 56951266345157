import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import moment from "moment";
import VueMoment from "vue-moment";
import "moment/locale/id";

// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(VueLoading)

Vue.filter('two_digits', function (value) {
  if(value.toString().length <= 1)
  {
    return "0"+value.toString();
  }
  return value.toString();
});

import vSelect from "vue-select";

Vue.component("vue-select", vSelect);
import "vue-select/src/scss/vue-select.scss";
import TinyMCE from "@/view/components/TinyMCE";
import mixin from "@/core/services/Helpers.js";
Vue.component("editor", TinyMCE);

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {  email } from 'vee-validate/dist/rules';

import user from "@/core/services/user.service";
extend("email",email)

extend("required", {
  mixins: [mixin],
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: "The {_field_} field must be filled"
});

Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

// Vue.use(VueQuillEditor);

Vue.use(VueMoment, {
  moment
});

import loadingOverlay from "@/core/plugins/loading-overlay";

Vue.prototype.$currentUser = user
Vue.prototype.$loadingOverlay = loadingOverlay

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

// router.beforeEach(async(to, from, next) => {
//   // console.log(await store.restored)
//   await store.restored
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   // store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
