

// Mutation
export const SET_USER = "setUserList";
export const SET_ERROR = "setError";
export const STORE_USER = "storeUser";
export const GET_USER_LIST = "getUserList";
export const DELETE_USER = "deleteUser";

export const ROLE_TEACHER = "teacher";
export const ROLE_STUDENT = "student";

export const DELETE_STUDENT = "deleteStudent";
export const DELETE_TEACHER = "deleteTeacher";

import ApiService from "@/core/services/api.service";
import Filesaver from "file-saver"

const baseURL = `${process.env.VUE_APP_API_BASE_URL}api/`

export const GET_STUDENT_TEMPLATE = `${baseURL}export/student-template?vendor_id=`;
export const GET_TEACHER_TEMPLATE = `${baseURL}export/teacher-template?vendor_id=`;

export const UPDATE_STUDENT = "updateStudent";
export const UPDATE_TEACHER = "updateTeacher";

export const IMPORT_USER = "importUser"

import { saveAs } from "file-saver";


const state = {
    userList: {

    },
    studentList: {

    },
    errors: null,
};

const getters = {
    currentUserList(state) {
        return state.userList;
    },
};

const actions = {

    [GET_USER_LIST](context, payload) {
        return new Promise((resolve, reject) => {

            ApiService.get('user/paginate?'+payload) 
                .then(({ data }) => {
                    context.commit(SET_USER, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [STORE_USER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`auth/register`, payload.body)
                .then(({ data }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("user/student/"+payload)
                .then(({ data }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("user/teacher/"+payload)
                .then(({ data }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },

    [GET_STUDENT_TEMPLATE](context,payload) {
        return new Promise((resolve, reject) => {
          ApiService.get("export/student-template?"+payload,{
            Headers : {
                'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            },
            responseType: 'arraybuffer'
          })
          .then(({ data }) => {

            saveAs(
                new Blob([data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }),
                "apa aja.xlsx"
              );
        
            resolve(data);
          })
          .catch(({ response }) => {
    
            reject();
        });
        })
      },
      [GET_TEACHER_TEMPLATE](context,payload) {
        return new Promise((resolve, reject) => {
          ApiService.get("export/teacher-template?"+payload , {
            responseType: 'blob'
          })
          .then(({ data }) => {
            Filesaver.saveAs(data,"contoh.xlsx");
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response);
            reject();
        });
        })
      },
      [UPDATE_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`user/student/${payload._id}`, payload.body)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`user/teacher/${payload._id}`, payload.body)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [IMPORT_USER](context, payload) {
        const formData = new FormData()
        for(let key in payload.body){
            formData.append(key, payload.body[key])
        }
        
        var url = `import/student`
        if(!payload.isSiswa) {
            url = `import/teacher`
        }

        return new Promise((resolve, reject) => {
            ApiService.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },


};


const mutations = {
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_USER](state, data) {
        state.userList = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};