export const getMenu = (role) => {
    const menu = [{
            to: '/dashboard',
            name: 'Dashboard',
            icon: 'media/svg/icons/Home/Home.svg',
            role: 'admin,teacher,student,vendor',
            separator: true
        },
        {
            to: '/guru',
            name: 'Guru',
            icon: 'media/svg/icons/General/User.svg',
            role: 'vendor'
        },
        {
            to: '/siswa',
            name: 'Siswa',
            icon: 'media/svg/icons/Communication/Group.svg',
            role: 'vendor',
        },
        {
            to: '/kelas',
            name: 'Kelas',
            icon: 'media/svg/icons/Home/Globe.svg',
            role: 'vendor',
            separator: true

        },
        {
            to: '/vendor',
            name: 'Vendor',
            icon: 'media/svg/icons/Files/File.svg',
            role: 'admin'
        },
        {
            to: '/mapel',
            name: 'Mata Pelajaran',
            icon: 'media/svg/icons/Home/Library.svg',
            role: ''
        },
        {
            to: '/ujian',
            name: 'Ujian',
            icon: 'media/svg/icons/Files/File.svg',
            role: 'teacher,student,vendor'
        },
        {
            to: '/histori-ujian',
            name: 'Riwayat Ujian',
            icon: 'media/svg/icons/General/Clipboard.svg',
            role: 'teacher,student'
        },
        {
            to: '/paket-soal',
            name: 'Paket Soal',
            icon: 'media/svg/icons/Home/Library.svg',
            role: 'vendor/teacher'
        },
        {
            to: '/bank-soal',
            name: 'Bank Soal',
            icon: 'media/svg/icons/Layout/Layout-4-blocks.svg',
            role: 'teacher,vendor'
        },
        {
            to: '/profile',
            name: 'profile',
            icon: 'media/svg/icons/Home/Library.svg',
            role: 'teacher,student,vendor,admin',
            show: false,
        },
        {
            to: '/attempt',
            icon: 'media/svg/icons/Home/Library.svg',
            role: 'student',
            show: false,
        }, {
            to: '/rekap-nilai',
            icon: 'media/svg/icons/General/Clipboard.svg',
            role: 'teacher,vendor',
            show: false,
        },
        {
            to: '/preview',
            icon: 'media/svg/icons/General/Clipboard.svg',
            role: 'teacher,vendor',
            show: false,
        }, {
            to: '/export',
            icon: 'media/svg/icons/General/Clipboard.svg',
            role: 'teacher,vendor',
            show: false,
        }
    ];
    return menu.filter(data => data.role.toLowerCase().includes(role))
}